import React, { Fragment } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import "../styles.css"

import Navbar from "./Navbar"
// import ExitModal from "./ExitModal"

export default function Layout(props) {
  const { location, title, children } = props
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  // const [modalOpen, setModalOpen] = useState(false)
  // const [shouldFireModal, setShouldFireModal] = useState(true)

  // var mouseExitIntent = e => {
  //   if (e.toElement === null && e.relatedTarget === null && shouldFireModal) {
  //     // bring in popup once mouse goes to exit page
  //     setModalOpen(true)
  //     setShouldFireModal(false)
  //   }
  // }
  // useEffect(() => {
  //   if (window.innerWidth >= 1024) {
  //     document.addEventListener("mouseout", mouseExitIntent)
  //   } else {
  //     setTimeout(() => {
  //       // bring in popup after 60s on mobile / iPad
  //       setModalOpen(true)
  //     }, 60000)
  //   }
  // })

  // useEffect(() => {
  //   if (shouldFireModal !== true) {
  //     console.log(shouldFireModal)
  //     document.removeEventListener("mouseout", mouseExitIntent)
  //   }
  // }, [shouldFireModal])

  if (location.pathname === rootPath) {
    header = (
      <h1
        className="text-5xl font-black"
        style={{
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        className="font-serif text-3xl font-bold"
        style={{
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <Fragment>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,600,900&display=swap&subset=cyrillic"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Josefin+Sans:400,400i,600,600i,700|Roboto+Slab:300,400,600,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: `42rem`,
          padding: `10px 30px 0px`,
        }}
      >
        <header className="flex items-baseline justify-between mb-4">
          {header}
          <Navbar />
        </header>
        <main>{children}</main>
        <footer className="mt-8 text-center">
          © {new Date().getFullYear()}, Daniel Schlabach
        </footer>
      </div>
      {/* <ExitModal isOpen={modalOpen} setModalOpen={setModalOpen} /> */}
    </Fragment>
  )
}
