import React, { Fragment, useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import Lottie from "react-lottie"
import * as animationData from "../utils/data.json"

import DropdownMenu from "./DropdownMenu"

export default function Navbar() {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [playAnimation, setPlayAnimation] = useState(false)

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <Fragment>
      <ul className="hidden sm:flex">
        <li className="mx-2">
          <Link className="hover:underline" to="/languages">
            Languages
          </Link>
        </li>
        <li className="mx-2">
          <Link className="hover:underline" to="/progress">
            Progress
          </Link>
        </li>
        <li className="mx-2">
          <Link className="hover:underline" to="/about">
            About
          </Link>
        </li>
        <li className="mx-2">
          <Link className="hover:underline" to="/tools">
            Tools
          </Link>
        </li>
      </ul>
      <div className="sm:hidden h-10 w-10 flex my-auto">
        <button
          onClick={() => {
            setDropdownOpen(!dropdownOpen)
          }}
          id="lottie"
          className="h-10 w-10 flex z-40"
        >
          <Lottie
            options={defaultOptions}
            isStopped={false}
            isPaused={false}
            direction={dropdownOpen ? 1 : -1}
            speed={1.2}
          ></Lottie>
        </button>
        {dropdownOpen ? <DropdownMenu></DropdownMenu> : null}
      </div>
    </Fragment>
  )
}
