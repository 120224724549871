import React from "react"
import { Link } from "gatsby"

export default function DropdownMenu() {
  return (
    <div className="sm:hidden absolute inset-0 p-8 rounded-lg shadow-xl bg-white z-30">
      <ul className="mt-20 ml-10 font-serif text-4xl text-indigo-700 font-bold">
        <li className="my-4">
          <Link className="hover:underline" to="/languages">
            Languages
          </Link>
        </li>
        <li className="my-4">
          <Link className="hover:underline" to="/progress">
            Progress
          </Link>
        </li>
        <li className="my-4">
          <Link className="hover:underline" to="/about">
            About
          </Link>
        </li>
        <li className="my-4">
          <Link className="hover:underline" to="/tools">
            Tools
          </Link>
        </li>
      </ul>
    </div>
  )
}
